import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: '[request]' */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "LandingView",
    component: loadView("LandingView"),
    meta: { title: "navigation.tabs.landing", visible: false },
  },
  {
    path: "/callback",
    name: "CallbackView",
    component: loadView("CallbackView"),
    meta: {
      title: "navigation.tabs.callback",
      visible: false,
      analyticsIgnore: true,
    },
    props: (route) => ({ code: route.query.code }),
  },
  {
    path: "/wiki",
    name: "WikiView",
    component: loadView("WikiView"),
    meta: { session: true, title: "navigation.tabs.wiki" },
  },
  {
    path: "/overview",
    name: "OverviewView",
    component: loadView("PageView"),
    meta: { title: "navigation.tabs.overview" },
    children: [
      {
        path: "/overview",
        name: "OverviewHome",
        component: loadView("overview/Home"),
        meta: { title: "navigation.overview.home" },
      },
    ],
  },
  {
    path: "/account",
    name: "AccountView",
    component: loadView("PageView"),
    meta: {
      session: true,
      title: "navigation.tabs.account",
      refresh: true,
    },
    children: [
      {
        path: "/account",
        name: "AccountProfile",
        component: loadView("account/Profile"),
        meta: { session: true, title: "navigation.account.profile" },
      },
      {
        path: "/account/events",
        name: "AccountEvents",
        component: loadView("account/Events"),
        meta: { session: true, title: "navigation.account.events" },
      },
      {
        path: "/account/emails",
        name: "AccountEmails",
        component: loadView("account/Emails"),
        meta: { session: true, title: "navigation.account.emails" },
      },
      {
        path: "/account/asps",
        name: "AccountAspEvents",
        component: loadView("account/AspEvents"),
        meta: { session: true, title: "navigation.account.asp_events" },
      },
      {
        path: "/account/asps/emails",
        name: "AccountAspEmails",
        component: loadView("account/AspEmails"),
        meta: { session: true, title: "navigation.account.asp_emails" },
      },
      {
        path: "/account/asps/emails/create",
        name: "AccountAspEmailsCreate",
        component: loadView("account/AspEmailsAdd"),
        meta: {
          session: true,
          visible: false,
          title: "navigation.emails.write",
        },
      },
      {
        path: "/account/asps/emails/edit/:id",
        name: "AccountAspEmailsEdit",
        component: loadView("account/AspEmailsEdit"),
        meta: {
          session: true,
          visible: false,
          title: "navigation.emails.edit",
        },
      },
      {
        path: "/account/settings",
        name: "AccountSettings",
        component: loadView("account/Settings"),
        meta: { session: true, title: "navigation.account.settings" },
      },
    ],
  },
  {
    path: "/events",
    name: "EventsView",
    component: loadView("PageView"),
    meta: { title: "navigation.tabs.events" },
    children: [
      {
        path: "/events",
        redirect: "/events/list",
        meta: { visible: false },
      },
      {
        path: "/events/list",
        name: "EventsList",
        component: loadView("events/EventsList"),
        meta: { title: "navigation.events.list" },
      },
      {
        path: "/events/list/:id",
        name: "EventsList",
        component: loadView("events/EventsList"),
        meta: { title: "navigation.events.list", visible: false },
      },
      {
        path: "/events/add",
        name: "EventsAdd",
        component: loadView("events/EventsAdd"),
        meta: {
          permissions: ["pool_employee", "operation", "network", "education"],
          session: true,
          title: "navigation.events.add",
        },
      } /*
            {
                path: '/events/participants',
                name: 'EventsParticipantsOverview',
                component: loadView('events/EventsParticipantsOverview'),
                meta: { permissions: ['operation', 'network', 'education'], session: true, title: "navigation.events.participants" }
            },*/,
      {
        path: "/events/participants/:id",
        name: "EventsParticipantsView",
        component: loadView("events/EventsParticipantsView"),
        meta: {
          session: true,
          visible: false,
        },
      },
      {
        path: "/events/edit/:id",
        name: "EventsEdit",
        component: loadView("events/EventsEdit"),
        meta: {
          session: true,
          visible: false,
        },
      },
      {
        path: "/events/:id",
        name: "EventView",
        component: loadView("events/EventsView"),
        meta: { visible: false },
      },
    ],
  },
  {
    path: "/users",
    name: "UsersView",
    component: loadView("PageView"),
    meta: {
      permissions: [
        "employee",
        "pool_employee",
        "operation",
        "network",
        "finance",
        "education",
        "socialmedia",
        "awareness",
        "asp",
        "other",
      ],
      session: true,
      title: "navigation.tabs.users",
    },
    children: [
      {
        path: "/users",
        name: "UsersList",
        component: loadView("users/UsersList"),
        meta: {
          permissions: [
            "employee",
            "pool_employee",
            "operation",
            "network",
            "finance",
            "education",
            "socialmedia",
            "awareness",
            "asp",
            "other",
          ],
          session: true,
          title: "navigation.users.list",
        },
      },
      {
        path: "/users/:id",
        name: "UsersDisplayView",
        component: loadView("users/UsersDisplay"),
        meta: { visible: false },
      } /*
            {
                path: '/users/active',
                name: 'UsersActiveList',
                component: loadView('users/List'),
                meta: { title: "navigation.users.active" },
            },
            {
                path: '/users/requested',
                name: 'UsersRequestedList',
                component: loadView('users/List'),
                meta: { title: "navigation.users.requested" },
            }*/,
    ],
  },
  {
    path: "/crew",
    name: "CrewDisplay",
    component: loadView("PageView"),
    meta: {
      session: true,
      crew: true,
      title: "navigation.tabs.crew",
    },
    children: [
      {
        path: "/crew",
        name: "CrewView",
        crew: true,
        component: loadView("network/crew/CrewDisplay"),
        meta: { title: "navigation.network.crew.own" },
      },
    ],
  },
  {
    path: "/network",
    name: "NetworkView",
    component: loadView("PageView"),
    meta: {
      session: true,
      permissions: ["employee", "pool_employee"],
      title: "navigation.tabs.network",
    },
    children: [
      {
        path: "/network",
        redirect: "/network/crews",
        meta: { visible: false, permissions: ["employee", "pool_employee"] },
      },
      {
        path: "/network/crews",
        name: "CrewList",
        component: loadView("network/CrewsList"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.crew.list",
        },
      } /**
      {
        path: "/network/crew/:id",
        name: "CrewView",
        component: loadView("network/CrewView"),
        meta: { visible: false },
      }, */,

      {
        path: "/network/crew/:id",
        name: "CrewView",
        component: loadView("network/crew/CrewDisplay"),
        meta: {
          visible: false,
          permissions: ["employee", "pool_employee"],
          title: "navigation.network.crew.edit",
        },
      },
      {
        path: "/network/crews/add",
        name: "CrewAdd",
        component: loadView("network/CrewsAdd"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.crew.add",
        },
      },
      {
        path: "/network/artists",
        name: "ArtistList",
        component: loadView("network/ArtistsList"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.artist.list",
        },
      },
      {
        path: "/network/artists/add",
        name: "ArtistAdd",
        component: loadView("network/ArtistsAdd"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.artist.add",
        },
      },
      {
        path: "/network/organizers",
        name: "OrganizerList",
        component: loadView("network/OrganizersList"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.organizer.list",
        },
      },
      {
        path: "/network/organizers/add",
        name: "OrganizerAdd",
        component: loadView("network/OrganizersAdd"),
        meta: {
          permissions: ["employee", "pool_employee"],
          session: true,
          title: "navigation.network.organizer.add",
        },
      },
      {
        path: "/network/organisations",
        name: "OrganisationView",
        component: loadView("organisations/OrganisationView"),
        //component: loadView("PageView"),
        meta: {
          session: true,
          permissions: ["admin"],
          title: "navigation.tabs.organisation",
        },
      },
    ],
  },
  {
    path: "/emails",
    name: "EmailsView",
    component: loadView("EmailsView"),
    meta: {
      permissions: ["operation", "network", "education"],
      session: true,
      title: "navigation.tabs.emails",
    },
    children: [
      {
        path: "/emails",
        redirect: "/emails/outbox",
        meta: {
          permissions: [
            "employee",
            "pool_employee",
            "operation",
            "network",
            "education",
          ],
          visible: false,
        },
      },
      {
        path: "/emails/outbox",
        name: "EmailOutboxList",
        component: loadView("emails/EmailOutboxList"),
        meta: {
          permissions: ["operation", "network", "education"],
          session: true,
          title: "navigation.emails.outbox",
        },
      },
      {
        path: "/emails/drafts",
        name: "EmailDraftsList",
        component: loadView("emails/EmailDraftList"),
        meta: {
          permissions: [
            "employee",
            "pool_employee",
            "operation",
            "network",
            "education",
          ],
          session: true,
          title: "navigation.emails.drafts",
        },
      },
      {
        path: "/emails/create",
        name: "EmailsAdd",
        component: loadView("emails/EmailAdd"),
        meta: {
          permissions: [
            "employee",
            "pool_employee",
            "operation",
            "network",
            "education",
          ],
          session: true,
          title: "navigation.emails.write",
        },
      },
      {
        path: "/emails/edit/:id",
        name: "EmailsEdit",
        component: loadView("emails/EmailEdit"),
        meta: {
          permissions: [
            "employee",
            "pool_employee",
            "operation",
            "network",
            "education",
          ],
          session: true,
          visible: false,
          title: "navigation.emails.edit",
        },
      },
    ],
  },
  {
    path: "/finances",
    name: "FinancesView",
    component: loadView("PageView"),
    meta: {
      permissions: ["employee", "pool_employee", "pool_finance", "finance"],
      session: true,
      title: "navigation.tabs.finance",
    },
    children: [
      {
        path: "/finances",
        redirect: "/finances/takings",
        meta: { visible: false },
      },
      {
        path: "/finances/takings",
        name: "TakingList",
        component: loadView("finance/TakingList"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          title: "navigation.finance.takings",
        },
      },
      {
        path: "/finances/takings/add",
        name: "TakingsAdd",
        component: loadView("finance/TakingAdd"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          title: "navigation.finance.taking_add",
        },
      },
      {
        path: "/finances/takings/edit/:id",
        name: "TakingsEdit",
        component: loadView("finance/TakingEdit"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          visible: false,
          title: "navigation.finance.taking_edit",
        },
      },
      {
        path: "/finances/deposits",
        name: "DepositList",
        component: loadView("finance/DepositList"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          title: "navigation.finance.deposits",
        },
      },
      {
        path: "/finances/deposits/add",
        name: "DepositAdd",
        component: loadView("finance/DepositAdd"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          title: "navigation.finance.deposits_add",
        },
      },
      {
        path: "/finances/deposit/edit/:id",
        name: "TakingsEdit",
        component: loadView("finance/DepositEditView"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance", "finance"],
          session: true,
          visible: false,
          title: "navigation.finance.deposits_edit",
        },
      },
      {
        path: "/finances/expenses",
        name: "ExpensesList",
        component: loadView("finance/ExpensesList"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance"],
          session: true,
          title: "navigation.finance.expenses",
        },
      },
      {
        path: "/finances/expenses/add",
        name: "ExpensesAdd",
        component: loadView("finance/ExpensesAdd"),
        meta: {
          permissions: ["employee", "pool_employee", "pool_finance"],
          session: true,
          title: "navigation.finance.expenses_add",
        },
      },
    ],
  },
  {
    path: "/faqs",
    name: "FaqsView",
    component: loadView("PageView"),
    meta: { title: "navigation.tabs.faqs" },
    children: [
      {
        path: "/faqs",
        name: "FaqsGeneral",
        component: loadView("faqs/General"),
        meta: { title: "navigation.faqs.general" },
      },
      {
        path: "/faqs/festivals",
        name: "FaqsFestivals",
        component: loadView("faqs/Festivals"),
        meta: { title: "navigation.faqs.festivals" },
      },
      {
        path: "/faqs/goldeimer",
        name: "FaqsGoldeimer",
        component: loadView("faqs/Goldeimer"),
        meta: { title: "navigation.faqs.goldeimer" },
      },
      {
        path: "/faqs/pool",
        name: "FaqsPool",
        component: loadView("faqs/Pool"),
        meta: { title: "navigation.faqs.pool" },
      },
    ],
  },
  {
    path: "/login",
    name: "LoginView",
    component: loadView("LoginView"),
    meta: {
      session: false,
      title: "navigation.tabs.login",
      analyticsIgnore: true,
    },
    props: (route) => ({
      code: route.query.code,
      callback: route.query.callback,
    }),
  },
  {
    path: "/register",
    name: "RegisterView",
    component: loadView("RegisterView"),
    meta: { session: false, title: "navigation.tabs.register" },
  },
  {
    path: "/logout",
    name: "LogoutView",
    component: loadView("LogoutView"),
    meta: {
      session: true,
      title: "navigation.tabs.logout",
      analyticsIgnore: true,
    },
  },
  {
    path: "*",
    name: "NotFoundView",
    component: loadView("errors/NotFound"),
    meta: { visible: false },
  },
];
var router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  store.dispatch({ type: "refresh" }).finally(() => {
    // First of all, if user has session and route needs permissions, check it
    if (to.meta.permissions != null) {
      if (store.state.user.current == undefined) {
        // no session? go to login.
        next({ name: "LoginView" });
        return;
      }

      if (store.getters["user/roles/hasSystemPermission"]()) {
        // If user has more than one system permission, its not only just a "member" and we check if the role fits
        const sysroles = store.state.user.current.system_roles.find(
          (el) =>
            el.name == "admin" ||
            to.meta.permissions.find((p) => el.name == p) != undefined
        );
        if (sysroles) {
          next();
          return;
        }
      }

      if (store.getters["user/roles/hasPoolPermission"]()) {
        // If we find the needed permission in the users roles, we redirect to the page
        const roles = store.state.user.roles.pool.find((el) =>
          to.meta.permissions.includes(el.name)
        );
        if (roles) {
          next();
          return;
        }
      }

      // else, redirect to 404
      next({ name: "NotFoundView" });
    } else if (to.meta.session != undefined) {
      if (
        (to.meta.session && store.state.user.current != null) ||
        (!to.meta.session && !store.state.user.current)
      ) {
        next();
      } else {
        if (store.state.user.current) {
          next({ name: "OverviewHome" });
        } else {
          next({ name: "LoginView" });
        }
      }
    } else {
      next();
    }
  });
});

export default router;
