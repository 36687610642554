import pdfmake from "pdfmake";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import VcaUi from "vca-ui";
import "vca-ui/dist/vca-ui.css";
import Vue from "vue";
import VueExcelXlsx from "vue-excel-xlsx";
import FlagIcon from "vue-flag-icon";
import VueI18n from "vue-i18n";
import VueMatomo from "vue-matomo";
import Notifications from "vue-notification";
import VueQuillEditor from "vue-quill-editor";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import { fromBinary, toBinary, toQuery } from "./mixins.js";
import router from "./router.js";
import store from "./store";

import de from "@/lang/de_DE.json";
import gb from "@/lang/en_GB.json";

Vue.use(Notifications);
Vue.use(VcaUi);
Vue.use(VueI18n);
Vue.use(Vuelidate);
Vue.use(pdfmake);
Vue.use(FlagIcon);
Vue.use(VueExcelXlsx);
Vue.use(VueQuillEditor);
Vue.config.productionTip = false;

if (
  process.env.VUE_APP_MATOMO_URL != "" &&
  process.env.VUE_APP_MATOMO_SITE_ID != ""
) {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: process.env.VUE_APP_MATOMO_URL,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: "matomo",

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: true,

    // Require consent before creating matomo session cookie
    // Default: false
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: true,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],

    // A function to determine whether to track an interaction as a site search
    // instead of as a page view. If not a function, all interactions will be
    // tracked as page views. Receives the new route as an argument, and
    // returns either an object of keyword, category (optional) and resultsCount
    // (optional) to track as a site search, or a falsey value to track as a page
    // view.
    // Default: false, i.e. track all interactions as page views
    // Example: (to) => {
    //   if (to.query.q && to.name === 'search') {
    //     return { keyword: to.query.q, category: to.params.category }
    //   } else {
    //    return null
    //   }
    // }
    trackSiteSearch: false,
  });
}

//const locale =  navigator.language;
const i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: "de",
  messages: {
    de: de,
    "de-DE": de,
    gb: gb,
    en: gb,
    "en-GB": gb,
    "en-US": gb,
  },
});

Vue.mixin({
  methods: {
    notification: function (msg) {
      if (msg !== undefined) {
        this.$notify({
          title: this.$t(msg.title),
          text: this.$t(msg.body),
          type: msg.type,
          duration: 6000,
        });
      }
    },
    date: function (date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return new Date(date * 1000).toLocaleDateString(
        this.$i18n.locale,
        options
      );
    },
    takingName(value) {
      if (value.name === "") {
        return value.event.name;
      }
      return value.name;
    },
    takingLink(value) {
      return "/finances/takings/edit/" + value;
    },
    eventLink(value) {
      return "/events/" + value;
    },
    datetime: function (date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      let current = new Date(date * 1000);
      return current.toLocaleDateString(this.$i18n.locale, options);
    },
    fromBinary: fromBinary,
    toBinary: toBinary,
    toQuery: toQuery,
    formatMoney: function (money) {
      let str_amount = (money.amount / 100).toFixed(2).replace(".", ",");
      switch (money.currency) {
        case "EUR":
          return str_amount + " €";
        case "USD":
          return str_amount + " $";
        case "CHF":
          return str_amount + " Fr.";
        default:
          return str_amount + " €";
      }
    },
  },
});

new Vue({
  i18n,
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
